import { Config, Environment } from "./types";

const env = process.env.NODE_ENV || "development";
const isProduction = env !== "development";

const prodEnvironments: Environment[] = [
  { name: "EMEA", url: "https://emea.inflosoftware.com/" },
  { name: "US", url: "https://us.inflosoftware.com/" },
  { name: "AMER", url: "https://amer.inflosoftware.com/" },
  { name: "APAC", url: "https://apac.inflosoftware.com/" },
  { name: "RSM US", url: "https://rsmus.inflosoftware.com/" },
  { name: "MA US", url: "https://maus.inflosoftware.com/" },
];

const devEnvironments: Environment[] = [{ name: "Staging", url: "https://staging.inflosoftware.com/" }];

export const config: Config = {
  CLIENT_ID: isProduction ? "de317d9d-c980-48ea-bc13-6adb466ac967" : "27ef75ca-914d-4f86-b49c-deab3352b07b",
  AUTH_URL: isProduction ? "https://identity.inflosoftware.com" : "https://identity-staging.inflosoftware.com",
  PHASES: [
    { key: "Planning", value: "10" },
    { key: "Fieldwork", value: "20" },
    { key: "Completion", value: "30" },
  ],

  ENVIRONMENTS: isProduction ? prodEnvironments : devEnvironments,
  SINGLE_ENGAGEMENT_FORMULA_CACHING_TIME: 1000 * 60, // 1 minute
  MULTI_ENGAGEMENT_FORMULA_CACHING_TIME: 1000 * 60, // 1 minute
};
