import { config } from "@inflo/config";
import { UserManager } from "oidc-client-ts";

const domain = window.location.origin;

const userManager = new UserManager({
  authority: config.AUTH_URL,
  client_id: config.CLIENT_ID,
  redirect_uri: domain + "/RedirectTest.html",
  response_type: "code",
  scope: "openid profile email inflo_apiserver ApiPublic AccountingDataExport",
  loadUserInfo: true,
  monitorAnonymousSession: true,
  filterProtocolClaims: true,
  revokeTokensOnSignout: true,
});

export async function startSignIn() {
  try {
    await userManager.signinRedirect();
  } catch (err) {
    console.error(err);
  }
}

export async function completeSignIn() {
  try {
    return await userManager.signinCallback();
  } catch (err) {
    console.error(err);
  }
}

export async function startSignOut() {
  try {
    return await userManager.signoutRedirect();
  } catch (err) {
    console.error(err);
  }
}
