import React, { useEffect } from "react";
import { startSignIn } from "../../utilities/Auth";

export default function Auth() {
  useEffect(() => {
    startSignIn();
  }, []);

  return <div></div>;
}
