import { config } from "@inflo/config";
import { User, UserManager } from "oidc-client-ts";
import { decodeJwtToken } from "./decodeJwt";

const domain = window.location.origin;

const userManager = new UserManager({
  authority: config.AUTH_URL,
  client_id: config.CLIENT_ID,
  redirect_uri: domain + "/RedirectTest.html",
  response_type: "code",
  scope: "openid profile email inflo_apiserver ApiPublic AccountingDataExport",
  loadUserInfo: true,
  monitorAnonymousSession: true,
  filterProtocolClaims: true,
  revokeTokensOnSignout: true,
});

export async function startSignIn() {
  try {
    await userManager.signinRedirect();
  } catch (err) {
    console.error(err);
  }
}

export async function completeSignIn() {
  try {
    return await userManager.signinCallback();
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function startSignOut() {
  try {
    return await userManager.signoutRedirect();
  } catch (err) {
    console.error(err);
  }
}

export async function storeUser(user: User) {
  return userManager.storeUser(user);
}

export async function getUser() {
  return userManager.getUser();
}

export async function removeUser() {
  return userManager.removeUser();
}

export function getUserFromAccessToken(accessToken: string): User {
  const decodedToken = decodeJwtToken(accessToken);

  return new User({
    access_token: accessToken,
    token_type: 'Bearer',
    profile: {
      iss: decodedToken["iss"],
      nbf: decodedToken["nbf"],
      iat: decodedToken["iat"],
      exp: decodedToken["exp"],
      aud: decodedToken["aud"],
      amr: decodedToken["amr"],
      sub: decodedToken["sub"],
      auth_time: decodedToken["auth_time"],
      sid: decodedToken["sid"],
      jti: decodedToken["jti"],
    },
  });
}
