export interface Phase {
  key: string;
  value: string;
}

export interface Environment {
  name: string;
  url: string;
}

export interface Config {
  CLIENT_ID: string;
  AUTH_URL: string;
  PHASES: Phase[];
  ENVIRONMENTS: Environment[];
  LEVEL: Level[]; // Already an array
  PERIODS: Period[]; // New array for Period
  BALANCE_TYPES: BalanceType[]; // New array for BalanceType
  LINKSTYPES: any[];
  SINGLE_ENGAGEMENT_FORMULA_CACHING_TIME: number;
  MULTI_ENGAGEMENT_FORMULA_CACHING_TIME: number;
}

export enum Period {
  Current = "Current",
  Prior = "Prior",
}

export enum Level {
  Classification = "Classification",
  Category = "Category",
  SubCategory = "SubCategory",
  Account = "Account",
}

export enum BalanceType {
  Original = "Original",
  Adjustments = "Adjustments",
  Adjusted = "Adjusted",
}

export interface DropdownValues {
  InfloLink: string;
  Environment: string;
  EngagementID: string;
  Phase: string | Phase;
  Period: string | Period;
  BalanceType: string | BalanceType;
  Level: string | Level | Number;
  ParentName: string;
  Name: string;
}
