import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import * as React from "react";
import { createRoot } from "react-dom/client";
import Auth from "./Auth";

const rootElement = document.getElementById("container1");
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
  root.render(
    <FluentProvider theme={webLightTheme}>
      <Auth />
    </FluentProvider>
  );
});

if (module.hot) {
  module.hot.accept("./Auth", () => {
    const NextApp = require("./Auth").default;
    root.render(NextApp);
  });
}
